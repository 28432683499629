import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {ButtonToolbar,InputGroup} from "reactstrap"
import {
    CTabs,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton,
    CButtonGroup,
    CWidgetProgress,
    CTooltip,
    CCard,
    CCardBody,
    CCardHeader
} from '@coreui/react'

import {contextAwareUrl, displayDateTime,displayDateTimeInContext} from '../../helpers'
import Player from './Player';
import {getEntity, deleteEntity} from "../../reducers/eventReducer";
import {getEntities as getEventAttendees, getStats} from "../../reducers/eventAttendeeReducer";
import EventButtons from './EventButtons';
import history from "../../history";
import EventAttendeeList from "./EventAttendeeList";
import {useInterval} from "../../hooks/useInterval";
import {encode,decode} from 'html-entities';
import {Badge, Card, } from "react-bootstrap";
import useModal from "../../hooks/useModal";

const EventDetail = ({id}) => {
    const dispatch = useDispatch();

    const event = useSelector(state => state.event.entity);
    const eventLoading = useSelector(state => state.event.loading);
    const updateSuccess = useSelector(state => state.event.updateSuccess);

    const eventAttendees = useSelector(state => state.eventAttendee.entities);
    const eventAttendeesLoading = useSelector(state => state.eventAttendee.loading);

    const eventAttendeeStats = useSelector(state => state.eventAttendee.stats);

    const [rmtps, setRmtps] = useState(true);

    const confirmDelete = () => {
        dispatch(deleteEntity(event));
    };

    const handleVideoUpdate = (videoEvent) => {
        console.log('handleVideoUpdate: ', videoEvent);
        dispatch(getEntity(id));
    };

    const handleEnableRmtps = () => {
        setRmtps(true);
    }

    const handleDisableRmtps = () => {
        setRmtps(false);
    }

    const [openDeleteModal] = useModal({
        cancelButton : 'Cancel',
        message : `This will permanently delete the event <b><i>${event.title}</i></b> on ${displayDateTimeInContext(event.start)}.`,
        proceedButton : 'Delete',
        title : 'Are you sure?',
        type : 'DANGER'
    });

    const handleDeleteModal = () => {
        openDeleteModal(confirmDelete);
    }

    const getStatusText = () => {
        switch (event.streamStatus) {
            case 'pending':
                return 'Pending';
            case 'open':
                return 'Open';
            case 'closed':
                return 'Closed';
            case 'live':
                return 'Online';
            case 'live_interrupted':
                return 'Offline';
            case 'live_finished':
                return 'Finished';
            case 'vod':
                return 'On Demand';
        }
    };

    useEffect(() => {
        if (updateSuccess) {
            history.push(contextAwareUrl('/events'));

        }
    }, [updateSuccess]);

    useEffect(() => {
        dispatch(getEntity(id));
    }, []);

    useEffect(() => {
        dispatch(getEventAttendees(id));
        dispatch(getStats(id));
    }, []);

    useInterval(() => {
        dispatch(getStats(id));
    }, 30000);

    return (
        <>
            {(eventAttendeesLoading) ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className="row align-items-start">
                        <div className="col-12 col-sm-8">
                            <h3 className="pb-3 card-title m-0">{event.title}</h3>
                            <span className="mb-4 h6 badge badge-secondary badge-pill">ID Number: {event.id}</span>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className={'d-flex justify-content-end mb-3'}>
                                <Link to={contextAwareUrl('/events/' + event.id + '/edit')}
                                      className="btn btn-primary mr-3">
                                    <span className="cil-pencil btn-icon mr-2"/> Edit
                                </Link>

                                <CButton onClick={handleDeleteModal} color="danger">
                                    <span className="cil-trash btn-icon mr-2" /> Delete
                                </CButton>
                            </div>
                        </div>
                    </div>

                    <CTabs activeTab="streaming">
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink data-tab="streaming">
                                    Stream Control
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="content">
                                    Content
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="audience">
                                    Audience
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent>
                            <CTabPane data-tab="streaming">
                                <div className={'card shadow-none'}>
                                    <div className={'card-body'}>
                                        <div className="row">

                                            <div className="col-12">

                                                <h3 className="pb-2">RTMP Settings</h3>
                                                <p>Stream to this url:</p>

                                                <ButtonToolbar>
                                                    <CButtonGroup className="mr-2">
                                                        <CButton onClick={handleEnableRmtps} color={ rmtps ? 'info' : 'secondary'}>RMTPS</CButton>
                                                        <CButton onClick={handleDisableRmtps} color={ rmtps ? 'secondary' : 'info'}>RMTP</CButton>
                                                    </CButtonGroup>
                                                    <InputGroup>
                                                        <div className="form-control">
                                                            <code className="highlight-rouge">{ rmtps ? `rtmps://global-live.mux.com:443/app/${event.streamKey}` : `rtmp://global-live.mux.com:5222/app/${event.streamKey}`}</code>
                                                        </div>
                                                    </InputGroup>
                                                </ButtonToolbar>

                                                {/*<CButton color="success" className="mt-3">Copy stream key</CButton>*/}

                                            </div>

                                            <div className="col-12">

                                                <hr className="my-4" />

                                                <h3 className="pb-2">Streaming Status</h3>

                                                <div className="row">
                                                    <div className="col-8">

                                                        <CCard>
                                                            <CCardHeader>
                                                                <CTooltip content="Event is upcoming">
                                                                    <Badge variant={ event.streamStatus === 'pending' ? 'info' : 'light'} className="mr-2">PENDING</Badge>
                                                                </CTooltip>

                                                                <CTooltip content="Users can go into 'watch' page and see countdown timer">
                                                                    <Badge variant={ event.streamStatus === 'open' ? 'success' : 'light'} className="mr-2">OPEN</Badge>
                                                                </CTooltip>

                                                                <CTooltip content="Users can see live stream">
                                                                    <Badge variant={ event.streamStatus === 'live' ? 'danger' : 'light'} className="mr-2">ON AIR</Badge>
                                                                </CTooltip>

                                                                <CTooltip content="Interrupted live stream - users see an 'interrupted' message">
                                                                    <Badge variant={ event.streamStatus === 'live_interrupted' ? 'warning' : 'light'} className="mr-2">OFF AIR</Badge>
                                                                </CTooltip>

                                                                <CTooltip content="Event has finished, live stream stopped">
                                                                    <Badge variant={ event.streamStatus === 'live_finished' ? 'success' : 'light'} className="mr-2">FINISHED</Badge>
                                                                </CTooltip>

                                                                <CTooltip content="Users can see event on video on demand, if available">
                                                                    <Badge variant={ event.streamStatus === 'vod' ? 'info' : 'light'} className="mr-2">VIDEO ON DEMAND</Badge>
                                                                </CTooltip>

                                                                <CTooltip content="Event can no longer be watched in any mode">
                                                                    <Badge variant={ event.streamStatus === 'closed' ? 'dark' : 'light'}>CLOSED</Badge>
                                                                </CTooltip>
                                                            </CCardHeader>
                                                            <CCardBody>
                                                                <EventButtons event={event} handleVideoUpdate={handleVideoUpdate} />
                                                            </CCardBody>
                                                        </CCard>

                                                    </div>
                                                    <div className="col-2">
                                                        <CWidgetProgress
                                                            color="primary"
                                                            header={eventAttendeeStats?.currentUsers}
                                                            text="Online Viewers"
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <CWidgetProgress
                                                            color="success"
                                                            header={eventAttendeeStats?.totalViewers}
                                                            text="Total Viewers"
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-12">

                                                <hr className="my-4" />

                                                <h3 className="pb-2">Streaming Previews</h3>

                                                <div className="row">

                                                    <div className="col">
                                                        <h4 className="my-2">Live &amp; linear</h4>
                                                        <Player
                                                            streamPlaybackId={event.streamPlaybackId}
                                                            dataStatus={event.dataStatus} />
                                                    </div>

                                                    <div className="col">
                                                        {event.streamEnableVod && (
                                                            <>
                                                                <h4 className="my-2">Video on demand</h4>

                                                                <Player
                                                                    src={"https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8"}/>
                                                            </>
                                                        )}
                                                    </div>


                                                </div>

                                            </div>

                                            {/*<div className="col-12">

                                                <hr className="my-4" />


                                                        <h3>Embed code</h3>

                                                        <p>Please put this inside the <code className="highlight-rouge">{decode('<head></head>')}</code> tags in your page:</p>

                                                        <div className="form-control">
                                                            <code className="highlight-rouge">{decode('<script type="text/javascript" src="https://scripts.440.io/v1/orchestra.min.js"></script>')}</code>
                                                        </div>

                                                        <CButton color="success" className="mt-3">Copy script code</CButton>

                                                        <p className="mt-3">Please put this inside the <code className="highlight-rouge">{decode('<body></body>')}</code> tags where you want the player to appear:</p>

                                                        <div className="form-control" style={{height: "100px"}}>
                                                            <code className="highlight-rouge">
                                                                { decode(`<div id="event-${event.id}"></div>`)}
                                                                <br />
                                                                { decode(`<script type="text/javascript">var conductor = new _440Conductor('41cad05c-6a93-4cd2-aa07-5db7ac3c86a0', '${event.id}');</script>`)}
                                                            </code>


                                                        </div>

                                                        <CButton color="success" className="mt-3">Copy embed code</CButton>

                                            </div>*/}

                                        </div>
                                    </div>
                                </div>
                            </CTabPane>

                            <CTabPane data-tab="content">
                                <div className={'card shadow-none'}>
                                    <div className={'card-body'}>
                                        <dl className="row">
                                            <dt className="col-sm-2">
                                                Starts
                                            </dt>
                                            <dd className="col-sm-10">
                                                <div className="mb-3">
                                                    {displayDateTime(event.start)}
                                                </div>
                                            </dd>
                                            {event.slug && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Slug
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            {event.slug}
                                                        </div>
                                                    </dd>
                                                </>
                                            )}
                                            {event.introduction && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Introduction
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div dangerouslySetInnerHTML={{__html: event.introduction}}/>
                                                    </dd>
                                                </>
                                            )}
                                            {event.description && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Description
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div dangerouslySetInnerHTML={{__html: event.description}}/>
                                                    </dd>
                                                </>
                                            )}
                                            {event.custom && event.custom.hero && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Hero
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            <img src={event.custom.hero} className="img-thumbnail w-25" />
                                                        </div>
                                                    </dd>
                                                </>
                                            )}
                                            {event.custom && event.custom.thumbnail && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Thumbnail
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            <img src={event.custom.thumbnail} className="img-thumbnail w-25" />
                                                        </div>
                                                    </dd>
                                                </>
                                            )}
                                        </dl>
                                    </div>

                                    <div className={'card-header'}>
                                        <strong>Meta</strong>
                                    </div>

                                    <div className={'card-body'}>
                                        <dl className="row">
                                            {event.metaDescription && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Description
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <p>{event.metaDescription}</p>
                                                    </dd>
                                                </>
                                            )}
                                            {event.custom && event.custom.twitter && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Twitter Preview
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            <img src={event.custom.twitter} className="img-thumbnail w-25" />
                                                        </div>
                                                    </dd>
                                                </>
                                            )}
                                            {event.custom && event.custom.facebook && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Facebook Preview
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            <img src={event.custom.facebook} className="img-thumbnail w-25" />
                                                        </div>
                                                    </dd>
                                                </>
                                            )}
                                        </dl>
                                    </div>

                                    <div className={'card-header'}>
                                        <strong>Information</strong>
                                    </div>

                                    <div className={'card-body'}>
                                        <dl className="row">
                                            {(event.custom && event.custom.people && event.custom.people.length > 0) && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        People
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            {event.custom.people.map((person, index) => {
                                                                let itemText = `${person.name} - ${person.title}`;

                                                                return (
                                                                    <div key={`person-${index}`}>
                                                                        {itemText}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </dd>
                                                </>
                                            )}

                                            {(event.custom && event.custom.programme && event.custom.programme.length > 0) && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Programme
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            {event.custom.programme.map((item, index) => {
                                                                let itemText = `${item.composer} - ${item.title}`;

                                                                if (item.arranger) {
                                                                    itemText = itemText + `, ${item.arranger}`;
                                                                }

                                                                itemText = itemText + `[${item.length}]`;

                                                                return (
                                                                    <div key={`programme-item-${index}`}>
                                                                        {itemText}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </dd>
                                                </>
                                            )}
                                        </dl>
                                    </div>
                                </div>
                            </CTabPane>

                            <CTabPane data-tab="audience">
                                {eventAttendees && (
                                    <EventAttendeeList attendeeData={eventAttendees.attendees} />
                                )}
                            </CTabPane>
                        </CTabContent>
                    </CTabs>
                </>
            )}
        </>
    )
}

export default EventDetail;
