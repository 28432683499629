import React from 'react';
import formConfig from "../../config/formConfig.json";
import {CFormGroup, CLabel} from "@coreui/react";
import TextField from "../common/TextField";

const EventUpdateFormMediaAssets = ({form}) => {

    return <>

        <h3 className="py-2">Media Assets</h3>

        {/*<CFormGroup>*/}
        {/*    <CLabel htmlFor="hero">Hero Image</CLabel>*/}
        {/*    <CInputFile id="hero"/>*/}
        {/*    <CFormText className="help-block">Upload a hero image for this event</CFormText>*/}

        {/*    {event.custom && event.custom.hero && (*/}
        {/*        <div className="mb-3">*/}
        {/*            <img src={event.custom.hero} className="img-thumbnail w-25" />*/}
        {/*        </div>*/}
        {/*    )}*/}
        {/*</CFormGroup>*/}

        {/*<CFormGroup>*/}
        {/*    <CLabel htmlFor="thumbnail">Thumbnail Image</CLabel>*/}
        {/*    <CInputFile id="thumbnail"/>*/}
        {/*    <CFormText className="help-block">Upload a preview image for this event</CFormText>*/}

        {/*    {event.custom && event.custom.thumbnail && (*/}
        {/*        <div className="mb-3">*/}
        {/*            <img src={event.custom.thumbnail} className="img-thumbnail w-25" />*/}
        {/*        </div>*/}
        {/*    )}*/}
        {/*</CFormGroup>*/}

        { formConfig.config.fields?.hero?.show &&
            <TextField
                name="custom.hero"
                config={formConfig.config.fields?.hero}
                form={form}
                />
        }
        { formConfig.config.fields?.thumbnail?.show &&
            <TextField
                name="custom.thumbnail"
                config={formConfig.config?.fields?.thumbnail}
                form={form}
            />
        }
        { formConfig.config.fields?.trailerUrl?.show &&
        <TextField
            name="custom.trailerUrl"
            config={formConfig.config?.fields?.trailerUrl}
            form={form}
        />
        }

    </>

}

export  default EventUpdateFormMediaAssets;